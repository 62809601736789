.App {
  text-align: center;
}

#root {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.overview-markdown-container,
.overview-markdown-container p,
.overview-markdown-container h1,
.overview-markdown-container h2,
.overview-markdown-container h3,
.overview-markdown-container h4,
.overview-markdown-container h5,
.overview-markdown-container ul {
  max-width: 775px;
  width: auto;
}



.overview-markdown-container {
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  align-items: flex-start;
}

.overview-markdown-container pre {
  color: #F2F2F2;
  max-width: 750px;
  width: auto;
  overflow-x: auto;
  font-size: 14px;
}

.overview-markdown-container p img {
  object-fit: contain;
  max-width: 775px;
  width: auto;
}

.overview-markdown-container h1 {
  margin: 0 0 18px;
}

.overview-markdown-container h2 {
  margin: 18px 0 24px;
}

.overview-markdown-container h3,
.overview-markdown-container h4,
.overview-markdown-container h5,
.overview-markdown-container li,
.overview-markdown-container p {
  margin: 12px 0;
}

.overview-markdown-container ul {
  margin: 12px 32px;
}

.header-wrapper {
  position: relative;
  cursor: pointer;
}

.header-wrapper .header-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
  z-index: 1;
  display: none;
}

.header-wrapper:hover .header-link {
  display: block;
}
